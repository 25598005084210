<template>
  <el-card class="box-card mb-20">
    <template #header>
      <span class="SubHead">昨日数据</span>
    </template>
    <el-row :gutter="10">
      <el-col :span="6">
        <el-card class="box-card before-data" shadow="never">
          <el-row class="header" justify="space-between">
            <span class="Text">昨日总消耗量</span>
            <el-tooltip class="item" effect="light" content="" placement="top">
              <template #content>
                昨日总消耗量：<br/>
                昨日全部广告账户广告消耗量的综合<br/>
                日均消耗量：<br/>
                广告账户一周内消耗量平均值
              </template>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </el-row>
          <p class="MainHead p-mg-reset">{{ $filters.thousands(dashboard.yesterday_spent) }}</p>
          <el-row class="content">
            <el-col :span="12">
              <p class="p-mg-reset">
                <span class="Text">周同比</span>
                <i class="el-icon-caret-top" v-if="dashboard.week_spent_type == 1"></i>
                <i class="el-icon-caret-bottom" v-else-if="dashboard.week_spent_type == 2"></i>
                {{ !dashboard.week_on_week_spent ? '-' : `${dashboard.week_on_week_spent}%` }}
              </p>
            </el-col>
            <el-col :span="12">
              <p class="p-mg-reset">
                <span class="Text">日环比</span>
                <i class="el-icon-caret-top" v-if="dashboard.day_spent_type == 1"></i>
                <i class="el-icon-caret-bottom" v-else-if="dashboard.day_spent_type == 2"></i>
                {{ !dashboard.day_to_day_spent ? '-' : `${dashboard.day_to_day_spent}%` }}
              </p>
            </el-col>
          </el-row>
          <el-divider/>
          <span class="HelpText">日均消耗量：{{ $filters.thousands(dashboard.day_avg_spent) }}</span>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card before-data" shadow="never">
          <el-row class="header" justify="space-between">
            <span class="Text">CPM</span>
            <el-tooltip class="item" effect="light" content="" placement="top">
              <template #content>
                CPM：<br/>
                平均每一千人看到广告一次一共需要的广告成本<br/>
                日均点击量：<br/>
                广告账户一周内点击量平均值
              </template>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </el-row>
          <p class="MainHead p-mg-reset">{{ $filters.thousands(dashboard.yesterday_cpm) }}</p>
          <el-row class="content" id="cpm-chart"></el-row>
          <el-divider/>
          <span class="HelpText">日均点击量：{{ $filters.thousands(dashboard.day_avg_click) }}</span>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card before-data" shadow="never">
          <el-row class="header" justify="space-between">
            <span class="Text">CTR</span>
            <el-tooltip class="item" effect="light" content="" placement="top">
              <template #content>
                CTR：<br/>
                广告的实际点击次数除以广告的展现量
              </template>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </el-row>
          <p class="MainHead p-mg-reset">{{ dashboard.yesterday_ctr !== undefined ? `${dashboard.yesterday_ctr}%` : '-' }}</p>
          <el-row class="content">
            <el-progress
                :text-inside="true"
                color="#2b82ff"
                :stroke-width="30"
                :percentage="dashboard.yesterday_ctr ? dashboard.yesterday_ctr * 10 : 0"
                style="margin: 35px 0 8px;">
              <span>{{ dashboard.yesterday_ctr ? `${dashboard.yesterday_ctr}%` : '0%' }}</span>
            </el-progress>
            <el-col :span="24">
              <el-row justify="space-between">
                <span class="Text">0%</span>
                <span class="Text">10%</span>
              </el-row>
            </el-col>
          </el-row>
          <el-divider/>
          <el-row class="HelpText">
            <el-col :span="12">
              <span class="p-mg-reset">
                <span>周同比</span>
                <i class="el-icon-caret-top" v-if="dashboard.week_ctr_type == 1"></i>
                <i class="el-icon-caret-bottom" v-else-if="dashboard.week_ctr_type == 2"></i>
                {{ !dashboard.week_on_week_ctr ? '-' : `${dashboard.week_on_week_ctr}%` }}
              </span>
            </el-col>
            <el-col :span="12">
              <span class="p-mg-reset">
                <span>日环比</span>
                <i class="el-icon-caret-top" v-if="dashboard.day_ctr_type == 1"></i>
                <i class="el-icon-caret-bottom" v-else-if="dashboard.day_ctr_type == 2"></i>
                {{ !dashboard.day_to_day_ctr ? '-' : `${dashboard.day_ctr_type}%` }}
              </span>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card before-data" shadow="never">
          <el-row class="header" justify="space-between">
            <span class="Text">转化率</span>
            <el-tooltip class="item" effect="light" content="" placement="top">
              <template #content>
                转化率：<br/>
                以百分比形式表示每次广告互动带来的平均转化次数。<br/>
              </template>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </el-row>
          <p class="MainHead p-mg-reset">{{ $filters.thousands(dashboard.yesterday_roas) }}</p>
          <el-row class="content" id="roas-chart"></el-row>
          <el-divider/>
          <span class="HelpText">比平均水平
            <i v-if="dashboard.avg_roas_type == 1">高</i>
            <i v-else-if="dashboard.avg_roas_type == 2">低</i>
            <i class="el-icon-caret-top" v-if="dashboard.avg_roas_type == 1"></i>
            <i class="el-icon-caret-bottom" v-else-if="dashboard.avg_roas_type == 2"></i>
            {{ dashboard.avg_roas ? `${dashboard.avg_roas}%` : '-' }}
          </span>
        </el-card>
      </el-col>
    </el-row>
  </el-card>
  <daily-spend></daily-spend>

  <el-card>
    <template #header>
      <span class="SubHead">账户成效分析</span>
    </template>
    <div class="roasList">
      <p class="Text cut-p">ROAS</p>
      <p class="cut-p">
        <span class="MainHead" style="margin-right: 30px;">{{ dashboard.sum_roas }}</span>
        <span class="HelpText">周同比 {{ dashboard.week_on_week_roas }}
          <i v-if="dashboard.week_roas_type != 0">%</i>
          <i class="el-icon-top color-success" v-if="dashboard.week_roas_type == 1"></i>
          <i class="el-icon-bottom color-danger" v-else-if="dashboard.week_roas_type == 2"></i>
        </span>
      </p>
      <!-- ROAS表单 -->
      <el-row id="roas-data-chart" class="roas-data-chart"></el-row>
      <el-table
          ref="roasListRef"
          :data="dashboard.roas"
          row-key="account_id">
        <el-table-column property="top" label="排名" width="100px"/>
        <el-table-column property="account_id" label="广告ID"/>
        <el-table-column property="sum_roas" label="ROAS" sortable/>
        <el-table-column label="周涨幅">
          <template #default="{row}">
            {{ row.contrast }}<i v-show="row.type != 0">%</i>
            <i class="el-icon-top color-success" v-if="row.type == 1"></i>
            <i class="el-icon-bottom color-danger" v-else-if="row.type == 2"></i>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty :image-size="200">
            <template #image>
              <img src="@/assets/empty.gif"/>
            </template>
          </el-empty>
        </template>
      </el-table>
    </div>
  </el-card>


</template>

<script setup>
import api from '@/request/api/account'
import {onMounted, ref} from "vue";
import tools from "@/views/ads/analysis/common/tools";
import DailySpend from "@/views/ads/analysis/common/DailySpend";

let dashboard = ref({})

let getDashboardData = async () => {
  let res = await api.bing.dashboard()
  dashboard.value = res.data ? res.data : {}
  tools.initChart('cpm-chart', {type: 'line', color: ['#7749fc'], areaStyle: {}}, {
    xAxis: dashboard.value.week_cpm.xAxis,
    series: dashboard.value.week_cpm.series,
    grid: {top: '5px', left: '5px', right: '5px', bottom: '5px'}
  })

  tools.initChart('roas-chart', {type: 'bar', color: ['#2b82ff']}, {
    xAxis: dashboard.value.week_roas.xAxis,
    series: dashboard.value.week_roas.series,
    grid: {top: '5px', bottom: '5px'}
  })

  tools.initChart('roas-data-chart', {type: 'line', color: ['#2b82ff'], areaStyle: {}, smooth: true}, {
    xAxis: dashboard.value.week_roas.xAxis,
    series: dashboard.value.week_roas.series,
    grid: {top: '0px', left: '5px', right: '5px', bottom: '0px'}
  })
}

onMounted(() => {
  getDashboardData()
})

</script>

<style scoped>
.before-data {
  height: 230px;
}

i.el-icon-warning-outline {
  font-size: 18px;
  color: #909399;
  cursor: pointer;
}

.p-mg-reset {
  margin: 12px 0 10px 0;
}

.el-divider {
  margin: 10px 0 !important;
}

.content {
  height: 84px;
}

.content .el-col {
  margin: auto;
}

.content .el-progress {
  width: 100%;
}

.roas-data-chart {
  height: 100px;
}
</style>